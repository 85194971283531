/*
 * @Descripttion: tengwei
 * @Author: tengwei
 * @Date: 2022-08-23 19:02:41
 * @LastEditors: tengwei
 * @LastEditTime: 2023-04-13 10:32:50
 */
import { fetchGetOrderPreview } from '@/apis/order'

const state = {
  orderList: []
}

const getters = {}

const mutations = {
  SET_ORDERS_LIST(state: { orderList: any }, payload: any) {
    state.orderList = payload
  }
}

const actions = {
  async fetchGetOrderPreview({ commit }: any, { addressId, orderSkuBOList }: any) {
    try {
      const { data }: any = await fetchGetOrderPreview({ addressId, orderSkuBOList })
      console.log('====', data, commit)
    } catch (error) {
      console.error('error ----> ', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
