import { getQueryString } from '@/utils/url'
/**
 * check browser type
 */
const BROWSER = (function () {
  if (typeof window === 'undefined') return
  const ua = navigator.userAgent.toLowerCase(),
    isAndroid = ua.indexOf('android') !== -1 ? 1 : 0

  const isAndroidOSVersion = ua.match(/android\s([0-9\\.]*)/), // 获取安卓版本号
    isiOSOSVersion = ua.match(/cpu iphone os (.*?) like mac os/) // 获取ios版本号

  return {
    isAndroid,
    isiOS: Boolean(ua.match(/\(i[^;]+;( u;)? cpu.+mac os x/)),
    isiPhone: ua.indexOf('iphone') > -1 || ua.indexOf('mac') > -1,
    isiPad: ua.indexOf('ipad') > -1,
    isWeChat: ua.indexOf('micromessenger') !== -1 ? 1 : 0,
    isQQ: Boolean(ua.match(/QQ/i)),
    isWeiBo: Boolean(ua.match(/Weibo/i)),
    androidVersion: isAndroid ? ua.substr(ua.indexOf('android') + 8, 1) : false,
    isAndroidOSVer: isAndroidOSVersion ? isAndroidOSVersion[1] : false,
    isiOSOSVer: isiOSOSVersion ? isiOSOSVersion[1] : false,
    isOldMallApp: ua.indexOf('mallbrowser') > -1 ? 1 : 0 // 是否是老商城app环境
  }
})()

export function isIphoneX() {
  if (getQueryString('isIphoneX') === '1') {
    return true
  } else if (window.screen.width === 375 && window.screen.height === 812) {
    return true
  }
  return false
}

export default BROWSER
