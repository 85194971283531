/*
 * @Author: your name
 * @Date: 2021-10-25 09:30:08
 * @LastEditTime: 2021-11-11 10:49:46
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/store/index.ts
 */
// import Vue from 'vue'
import { createStore as _createStore } from 'vuex'
import home from './modules/home'
import good from './modules/good'
import cart from './modules/cart'
import login from './modules/login'
import search from './modules/search'
import detail from './modules/detail'
import user from './modules/user'
import address from './modules/address'
import order from './modules/order'

// const modules = import.meta.glob('./modules/*.ts')

const myPlugin = (store: { subscribe: (arg0: (mutation: any, state: any) => void) => void }) => {
  store.subscribe((mutation: { type: string }, state: { cart: { cartProducts: any } }) => {
    if (mutation.type.startsWith('cart/')) {
      window.localStorage.setItem('cart-products', JSON.stringify(state.cart.cartProducts))
    }
  })
}

export function createStore() {
  return _createStore({
    state: {},
    getters: {},
    mutations: {},
    actions: {},
    modules: { home, good, cart, search, detail, user, address, order, login },
    plugins: [myPlugin]
  })
}
