/*
 * @Author: your name
 * @Date: 2021-11-15 13:46:24
 * @LastEditTime: 2023-03-02 16:14:42
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/apis/address.ts
 */
import axios from '@/utils/request'

/**
 * 查询同级区域
 * @returns
 */
export const getRegion = (parentCode = '') => {
  return axios({
    method: 'get',
    url: `/goods-api/goods/region/list-same-level?parentCode=${parentCode}`
  })
}

/**
 * 获取地址列表
 * @returns
 */
export const getAddressList = () => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/receive/address/list'
  })
}

/**
 * 保存地址
 * @param data
 * @returns
 */
export const saveAddress = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/receive/address/save',
    data
  })
}

/**
 * 运费计算
 * @param param0
 * @returns
 */
export const calculateFee = (data: any) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/spu/calculate-delivery-fee/v2',
    data
  })
}

/**
 * 根据id获取地址信息
 * @param addressId 地址ID
 * @returns
 */
export const findAddressById = (addressId: string) => {
  return axios({
    method: 'post',
    url: `/uc-api/uc/receive/address/findById?id=${addressId}`
  })
}

/**
 * 删除会员收货地址接口
 * @param idList
 * @returns
 */
export const delAddressById = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/receive/address/delete',
    data
  })
}
