/*
 * @Author: your name
 * @Date: 2021-11-11 10:46:44
 * @LastEditTime: 2023-03-02 10:47:49
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/apis/detail.ts
 */
// 详情相关接口
import axios from '@/utils/request'

/**
 * 获取详情页信息
 * @param id
 * @returns
 */
export const getGoodDetail = (id: string) => {
  return axios({
    method: 'get',
    url: `/goods-api/goods/sku/detail?id=${id}`
  })
}

/**
 * 获取商品详情
 * @param spuId
 * @returns
 * integer($int32)
 * 商品类型;1-普通实体商品,2-电子福卡,3-手机充值,4-麦当劳,5-积分，6-卡汇,7-加油卡
 */
export const getSpuDetail = (spuId: string) => {
  return axios({
    method: 'get',
    url: `/goods-api/goods/spu/detail/v2?spuId=${spuId}`
  })
}

/**
 * 根据spu id和规格列表查询规格集合和sku
 * @param data
 * @returns
 */
export const getSkuSpec = (data: any) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/sku/query-sku-by-spu-id-and-spec',
    data
  })
}

/**
 * 根据spuId获取商铺信息
 * @param spuId
 * @returns
 */
export const getMerchantInfoBySpu = (spuId: string) => {
  return axios({
    method: 'get',
    url: `/goods-api/goods/spu/merchant?spuId=${spuId}`
  })
}

/**
 * 预约/抢购按钮接口
 * @param activityId 活动ID
 * @returns
 */
export const joinActivity = (activityId: string) => {
  return axios({
    method: 'post',
    url: `/goods-api/goods/activity/join?activityId=${activityId}`
  })
}

/**
 * 获取商铺信息
 * @param id
 */
export const getShopInfo = (id: number) => {
  return axios({
    method: 'GET',
    url: `/goods-api/merchant/info/find-by-id?id=${id}`
  })
}
