/* eslint-disable */
// const initalEvent = require('./initalEvent')
import initalEvent from './initalEvent'

var yfBridge = {
  default: this, // for typescript
  call(method, args, cb) {
    let ret = ''
    if (typeof args === 'function') {
      cb = args
      args = {}
    }
    let arg = { data: args === undefined ? null : args }
    if (typeof cb === 'function') {
      const cbName = `dscb${window.dscb++}`
      window[cbName] = cb
      arg._dscbstub = cbName
    }
    arg = JSON.stringify(arg)

    if (window._dsbridge) {
      ret = _dsbridge.call(method, arg)
    } else if (window._dswk || navigator.userAgent.indexOf('_dsbridge') != -1) {
      ret = prompt(`_dsbridge=${method}`, arg)
    }

    return JSON.parse(ret || '{}').data
  },
  register(name, fun, asyn) {
    const q = asyn ? window._dsaf : window._dsf
    if (!window._dsInit) {
      window._dsInit = true
      // notify native that js apis register successfully on next event loop
      setTimeout(() => {
        yfBridge.call('_dsb.dsinit')
      }, 0)
    }
    if (typeof fun === 'object') {
      q._obs[name] = fun
    } else {
      q[name] = fun
    }
  },
  registerAsyn(name, fun) {
    this.register(name, fun, true)
  },
  hasNativeMethod(name, type) {
    return this.call('_dsb.hasNativeMethod', {
      name,
      type: type || 'all'
    })
  },
  disableJavascriptDialogBlock(disable) {
    this.call('_dsb.disableJavascriptDialogBlock', {
      disable: disable !== false
    })
  }
}

for (const key in initalEvent) {
  yfBridge[key] = initalEvent[key]
}

!(function () {
  if (window._dsf) return
  const ob = {
    _dsf: {
      _obs: {}
    },
    _dsaf: {
      _obs: {}
    },
    dscb: 0,
    dsBridge: yfBridge,
    close() {
      yfBridge.call('_dsb.closePage')
    },
    _handleMessageFromNative(info) {
      const arg = JSON.parse(info.data)
      const ret = {
        id: info.callbackId,
        complete: true
      }
      const f = this._dsf[info.method]
      const af = this._dsaf[info.method]
      const callSyn = function (f, ob) {
        ret.data = f.apply(ob, arg)
        yfBridge.call('_dsb.returnValue', ret)
      }
      const callAsyn = function (f, ob) {
        arg.push((data, complete) => {
          ret.data = data
          ret.complete = complete !== false
          yfBridge.call('_dsb.returnValue', ret)
        })
        f.apply(ob, arg)
      }
      if (f) {
        callSyn(f, this._dsf)
      } else if (af) {
        callAsyn(af, this._dsaf)
      } else {
        // with namespace
        const name = info.method.split('.')
        if (name.length < 2) return
        const method = name.pop()
        const namespace = name.join('.')
        let obs = this._dsf._obs
        let ob = obs[namespace] || {}
        let m = ob[method]
        if (m && typeof m === 'function') {
          callSyn(m, ob)
          return
        }
        obs = this._dsaf._obs
        ob = obs[namespace] || {}
        m = ob[method]
        if (m && typeof m === 'function') {
          callAsyn(m, ob)
        }
      }
    }
  }
  for (const attr in ob) {
    window[attr] = ob[attr]
  }
  yfBridge.register('_hasJavascriptMethod', (method, tag) => {
    const name = method.split('.')
    if (name.length < 2) {
      return !!(_dsf[name] || _dsaf[name])
    }
    // with namespace
    var method = name.pop()
    const namespace = name.join('.')
    const ob = _dsf._obs[namespace] || _dsaf._obs[namespace]
    return ob && !!ob[method]
  })
})()

export default yfBridge
