/**
 * 用户相关
 */

// 跳转登录页面
export function skipLoginPage(args = {}) {
  this.call('skipLoginPage', args)
}

// 获取用户信息
export function getUserInfo() {
  return this.call('getUserInfo')
}

// 检测用户是否是登录状态
export function checkSession() {
  return this.call('checkSession')
}
