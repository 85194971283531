import { loginCap, loginPwd, validCaptcha, resetPwd } from '@/apis/login'
import { getResetCaptcha } from '@/apis/user'
import { Toast } from 'vant'

export interface State {
  mobile?: string
  code?: string
  codeSuccess?: boolean
  type?: string
  titleRight?: string
  titleForm?: string
  sessionId?: string
  userId?: string
  resetPasswordSuccess?: boolean
  authActiveNum?: number // 实名认证页面的步骤
}

let SESSION: string | null | undefined
if (typeof window !== 'undefined') {
  // 判断当前环境是否是浏览器环境
  SESSION = localStorage.getItem('sessionId')
}

const state: State = {
  mobile: '',
  code: '',
  codeSuccess: false,
  type: 'pwd',
  sessionId: SESSION || '',
  userId: '',
  resetPasswordSuccess: false,
  authActiveNum: 0
}

const getters = {}

const mutations = {
  SET_CODE(state: State, payload: any) {
    state.code = payload
  },
  SET_CODE_SUCCESS(state: State, payload: any) {
    state.codeSuccess = payload
  },
  SET_TYPE(state: State, payload: any) {
    state.type = payload
  },
  SET_TOKEN(state: { sessionId: string }, payload: any) {
    localStorage.setItem('sessionId', payload.sessionId)
    localStorage.setItem('userInfo', JSON.stringify(payload))
    state.sessionId = payload
  },
  VALID_CAPTCHA_SUCCESS(state: { userId: string }, payload: string) {
    state.userId = payload
  },
  SET_PASSWORD_SUCCESS(state: { resetPasswordSuccess: boolean }) {
    state.resetPasswordSuccess = true
  },
  SET_AUTH_ACTIVITE_NUM(state: State, payload: number) {
    state.authActiveNum = payload
  }
}

const actions = {
  // 获取验证码(忘记密码时的验证码)
  async getCaptchaCode({ commit }: unknown, mobile: string) {
    const response: any = await getResetCaptcha(mobile)
    if (response.code === '000000') {
      commit('SET_CODE', response.data)
      commit('SET_CODE_SUCCESS', true)
    }
  },
  // 验证码登录
  async loginCap({ commit }: unknown, data: object) {
    const response: any = await loginCap(data)
    if (response.code !== '000000') return Toast(response.msg)
    commit('SET_TOKEN', response.data)
  },
  // 验证手机号验证码
  async validCaptcha({ commit }: unknown, data: object) {
    try {
      const response: any = await validCaptcha(data)
      if (response.code !== '000000') return Toast(response.msg)
      commit('VALID_CAPTCHA_SUCCESS', response.data)
    } catch (error) {
      console.log('error', error)
    }
  },
  // 账号密码登录
  async loginPwd({ commit }: unknown, data: object) {
    const response: any = await loginPwd(data)
    if (response.code !== '000000') return Toast(response.msg)
    commit('SET_TOKEN', response.data)
  },
  // 重置密码
  async resetPwd({ commit }: unknown, data: object) {
    const response: any = await resetPwd(data)
    if (response.code !== '000000') return Toast(response.msg)
    commit('SET_PASSWORD_SUCCESS')
  },
  changeType({ commit }: unknown, type: string) {
    commit('SET_TYPE', type)
  },
  // 设置实名认证的activite
  setAuthActiviteNum({ commit }: unknown, type: number) {
    commit('SET_AUTH_ACTIVITE_NUM', type)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
