import { getHomeInfo, getHomeTopics } from '@/apis/home'
import { Toast } from 'vant'
interface iconObj {
  name?: string
  type?: number
  url?: string
  title?: string
  desc?: string
  merType?: number | string
}

interface Store {
  tabLists: iconObj[]
  iconLists: iconObj[]
  tabBottomList: iconObj[]
  bannerList: []
  firstNewDatas?: object
  nextDatas?: object
  firstRecommendList?: any[]
  nextRecommendList?: any[]
  recommendTopicResList?: any[]
}

const state: Store = {
  bannerList: [],
  tabLists: [
    {
      name: '推荐',
      type: 1
    }
  ],
  iconLists: [],
  tabBottomList: [],
  firstNewDatas: {},
  firstRecommendList: [],
  nextDatas: {},
  nextRecommendList: [],
  recommendTopicResList: []
}

const getters = {}

const mutations = {
  SET_ICON_INFO(state: { iconLists: any }, payload: any) {
    state.iconLists = payload
  },
  SET_BANNER_INFO(state: { bannerList: any }, payload: any) {
    state.bannerList = payload
  },
  SET_ICON_BOTTOM(state: { tabBottomList: any }, payload: any) {
    state.tabBottomList = payload
  },
  SET_TOPICINFO(state: { tabLists: string | any[] }, payload: any) {
    state.tabLists = state.tabLists.concat(payload)
  },
  SET_FIRST_LISTS(state: { firstNewDatas: any; firstRecommendList: any }, payload: any) {
    state.firstNewDatas = payload
    state.firstRecommendList = [].concat(state.firstRecommendList, payload.items)
  },
  SET_NEXT_LISTS(state: { nextRecommendList: any }, payload: any) {
    state.nextRecommendList = payload
    // state.nextRecommendList = state.nextRecommendList.concat(payload.items)
    state.nextRecommendList = payload.items
  },
  SET_RECOMMEND_TOPIC_RESLIST(state: { recommendTopicResList: any }, payload: any) {
    state.recommendTopicResList = payload
  }
}

const actions = {
  // 获取精选首页信息
  async getHomeInfo({ commit }: any) {
    try {
      const { data, code }: any = await getHomeInfo()
      if (code === '000000') {
        const { bannerInfoRes, menuInfoRes, spuTopicResList, recommendTopicResList }: any = data
        if (bannerInfoRes.recommendContentResList.length !== 0)
          commit('SET_BANNER_INFO', bannerInfoRes.recommendContentResList)
        if (menuInfoRes.recommendContentResList.length !== 0)
          commit('SET_ICON_INFO', menuInfoRes.recommendContentResList)
        if (spuTopicResList.length !== 0) commit('SET_ICON_BOTTOM', spuTopicResList)
        if (recommendTopicResList.length !== 0)
          commit('SET_RECOMMEND_TOPIC_RESLIST', recommendTopicResList)
      }
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  // 首页精选底部推荐列表
  // async getHomeRecommendList(
  //   { commit }: any,
  //   { limit = 10, orderBy = '1', page = 1, req = 0 }: recommendReqType
  // ) {
  //   try {
  //     const { data }: any = await getHomeRecommendList({
  //       limit,
  //       orderBy,
  //       page,
  //       req
  //     })
  //     commit('SET_FIRST_LISTS', data)
  //   } catch (error) {
  //     console.error('error ------> ', error)
  //   }
  // },
  // 首页顶部Tab
  async getHomeTopics({ commit }: any) {
    try {
      const { data, code, msg }: any = await getHomeTopics()
      if (code !== '000000') return Toast(msg)
      commit('SET_TOPICINFO', data)
    } catch (error) {
      console.error('error ----> ', error)
    }
  }
  // async getPageCondition({ commit }: any, body: any) {
  //   try {
  //     const { data, code, msg }: any = await getPageCondition(body)
  //     if (code !== '000000') return Toast(msg)
  //     commit('SET_NEXT_LISTS', data)
  //   } catch (error) {
  //     console.error('error ---> ', error)
  //   }
  // }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
