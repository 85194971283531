/*
 * @Author: tengwei
 * @Date: 2022-09-01 16:56:35
 * @LastEditors: tengwei
 * @LastEditTime: 2023-02-16 14:59:25
 * @FilePath: /yufu_h5_ssr/src/utils/modules/aplusQueue.ts
 * @Description: H5多页面 接入友盟
 *
 */
import BROWSER from './browser'
const isProd = import.meta.env.MODE === 'production' || import.meta.env.MODE === 'release'

/**
 * 获取设备类型
 */
function getOsType() {
  if (BROWSER?.isAndroid) {
    return 'android'
  }
  if (BROWSER?.isiPad || BROWSER?.isiPhone) {
    return 'ios'
  }
  return 'others'
}

const soureEnum: any = {
  '1': '数字福卡',
  '2': '福卡',
  '6': '老商城',
  '7': '福鲤通'
}

/**
 * 随机生成字符串
 * @param e
 * @returns
 */
export function getRandomCode() {
  let result = ''
  if (localStorage.getItem('randomCode')) {
    result = localStorage.getItem('randomCode') as string
  } else {
    result = Math.random().toString(36).slice(2, 7) + Math.random().toString(36).slice(2, 7)
    localStorage.setItem('randomCode', result)
  }
  //
  return result
}

/**
 * 获取公共参数
 * @returns
 */
function getDefaultParams() {
  const source = sessionStorage.getItem('source') || '1'
  return {
    url: window.location.href, // 当前页面地址
    hash: getRandomCode(), // 用户未登录时生成一个随机数当标识
    operation_system: getOsType(), // 设备类型
    eventTime: Date.now(), // 时间戳
    isProd, // 开发环境
    source: soureEnum[source]
  }
}

/**
 * H5手动触发曝光上传
 * @param params 上传参数
 */
export const AplusQueusSendPV = (params: any = {}) => {
  console.log('开始进行友盟曝光')
  const { aplus_queue }: any = window
  aplus_queue.push({
    action: 'aplus.sendPV',
    arguments: [
      {
        is_auto: false
      },
      {
        ...getDefaultParams(),
        ...params
      }
    ]
  })
}

/**
 * H5 进行友盟点击事件上报
 * @param eventKeyCode 事件ID 或 事件编码 例如1、 H5_页面名_自定义名称 2、全大写
 * @param params 事件参数,例如：{page: 'home'}
 */
export const mBuryPoint = (eventKeyCode: string, params: any = {}) => {
  const { aplus_queue }: any = window
  aplus_queue.push({
    action: 'aplus.record',
    arguments: [
      eventKeyCode,
      'CLK',
      {
        ...getDefaultParams(),
        ...params
      }
    ]
  })
}

/**
 * 通知Native进行友盟埋点
 * @param eventKeyCode 事件ID 或 事件编码 1、 H5_页面名_自定义名称 2、全大写
 * @param params 事件参数,例如：{page: 'home'}
 */
export const nBuryPoint = (eventKeyCode: string, params: any = {}) => {
  console.log('Native 埋点', eventKeyCode, params)
  // const { aplus_queue }: any = window
  // aplus_queue.push({
  //   action: 'aplus.record',
  //   arguments: [
  //     eventKeyCode,
  //     'CLK',
  //     {
  //       ...getDefaultParams(),
  //       ...params
  //     }
  //   ]
  // })
}
