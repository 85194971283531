// 订单相关接口
import axios from '@/utils/request'

export type OrderSkuBOListType = {
  merId: string
  num: number
  originPrice: string
  sellPrice: string
  skuId: string
  spuId: string
  activityId?: string
  addFrom?: string
  rechargeAccount?: string
}
interface fetchGetOrderPreviewType {
  addressId: string | number
  rechargeAccount?: string | number
  orderSkuBOList: OrderSkuBOListType[]
}
/**
 * 获取确认订单的接口信息
 * @param data
 * @returns
 */
export const fetchGetOrderPreview = ({
  addressId,
  rechargeAccount,
  orderSkuBOList
}: fetchGetOrderPreviewType) => {
  return axios({
    method: 'post',
    url: '/order-api/order/preview',
    data: {
      addressId,
      rechargeAccount,
      orderSkuResList: orderSkuBOList
    }
  })
}

interface CreateOrderType {
  channelId?: string
  ifIntegral: 0 | 1 // 是否使用积分抵扣1-是;0-否
  ifSingleCard: 0 | 1 // 是否使用数字福卡抵扣 1-是；0-否
  orderSkuResList: OrderSkuBOListType[]
  platformId?: string
  receiveAddressId?: string
  source?: string
  totalPrice: string
  type: 0 | 1 // 0-购物车结算，1-立即购买结算
  useIntegral?: number // 使用积分数量
  goodsSpuType: number // 商品类型 1-普通实体商品 2-电子福卡 3-话费充值
  rechargeMobile?: string // 充值手机号
  orderRemarkResList?: string // 用户备注信息
  integralPaySmsCode?: string | number
  integralPlatformOrderId?: string | number
  singleCardSmsCode?: string // 数字福卡支付验证码
  singleCardList: any[] // 使用的数字福卡列表
  couponCode?: string // 使用优惠券
  singleCardServiceAmount?: string // 数字福卡手续费
  realNameAuthenticationId?: string | number // 海淘提交成功之后返回的ID
}
/**
 * 创建订单
 * @param param0
 */
export const fetchCreateOrder = ({
  channelId,
  ifIntegral,
  ifSingleCard,
  orderSkuResList,
  platformId,
  source,
  totalPrice,
  type,
  useIntegral,
  goodsSpuType,
  rechargeMobile,
  orderRemarkResList,
  receiveAddressId,
  integralPaySmsCode,
  singleCardSmsCode,
  integralPlatformOrderId,
  singleCardList,
  couponCode,
  singleCardServiceAmount,
  realNameAuthenticationId
}: CreateOrderType) => {
  return axios({
    method: 'post',
    url: '/order-api/order/creat/v2',
    data: {
      channelId,
      ifIntegral,
      ifSingleCard,
      integralPaySmsCode,
      singleCardSmsCode,
      integralPlatformOrderId,
      mobile:
        typeof window !== 'undefined' &&
        localStorage.getItem('userInfo') &&
        JSON.parse(localStorage.getItem('userInfo') as string).mobile,
      orderSkuResList,
      platformId,
      receiveAddressId,
      source,
      totalPrice,
      type,
      useIntegral,
      goodsSpuType,
      rechargeMobile:
        rechargeMobile ||
        (typeof window !== 'undefined' &&
          localStorage.getItem('userInfo') &&
          JSON.parse(localStorage.getItem('userInfo') as string).mobile),
      orderRemarkResList,
      singleCardList,
      couponCode,
      singleCardServiceAmount,
      realNameAuthenticationId
    }
  })
}

/**
 * 获取订单列表接口
 * @param orderStatus 订单状态1000-已下单，待支付;1001-超时自动取消;1002-支付前手动取消;1003-支付成功待发货;1004-待收货;1006-完成;
 * @returns
 */
export const fetchGetOrderList = (options: any = {}) => {
  const { limit = 10, page = 1, req = {} } = options
  return axios({
    method: 'post',
    url: '/order-api/order/list/page',
    data: {
      limit,
      page,
      req
    }
  })
}
/**
 * 获取各状态订单数量
 * @returns
 */
export const getAllOrderCount = () => {
  return axios({
    method: 'post',
    url: '/order-api/order/count'
  })
}

/**
 * 获取主订单详情
 * @param orderSn
 * @returns
 */
export const getOrderMainDetail = (orderSn: string | number) => {
  return axios({
    method: 'post',
    url: `/order-api/order/main/detail?orderSn=${orderSn}`
  })
}
/**
 * 获取主订单详情
 * @param orderSn
 * @returns
 */
export const getOrderSubDetail = (orderSn: string | number) => {
  return axios({
    method: 'post',
    url: `/order-api/order/sub/detail?orderSn=${orderSn}`
  })
}

/**
 * 用户未支付 手动取消订单
 * @param param0
 * @returns
 */
export const cancelOrder = (params: any) => {
  return axios({
    method: 'post',
    url: '/order-api/order/cancel',
    data: params
  })
}

/**
 * 用户删除订单
 * @param cancelOrderReq 订单号
 * @param type 订单类型:0-子订单；1-主订单
 * @returns
 */
export const delOrder = (cancelOrderReq: string, type: string | number) => {
  return axios({
    method: 'post',
    url: `/order-api/order/delete?cancelOrderReq=${cancelOrderReq}&type=${type}`
  })
}

/**
 * 获取快递物流信息
 * @param orderSn 快递单号
 * @param mobile 收/寄件人手机号后四位，顺丰快递必须填写本字段。
 * @returns
 */
export const fetchGetLogistics = (orderSn: string, mobile?: string) => {
  return axios({
    method: 'post',
    url: `/order-api/deliver/node-info?orderSn=${orderSn}&mobile=${mobile}`
  })
}

/**
 * 获取历史订单快递物流信息
 * @param deliverSn 快递单号
 * @returns
 */
export const fetchGetLogisticsHistory = (deliverSn: string) => {
  return axios({
    method: 'post',
    url: `/order-api/deliver/old-mall/node-info?orderSn=${deliverSn}`
  })
}

/**
 * 手机充值商品信息
 * @returns
 */
export const getMoblieRecharge = () => {
  return axios({
    method: 'get',
    url: '/goods-api/goods/spu/query-recharge'
  })
}

/**
 * 用户确认收货
 * @param subOrderSn
 * @param memberId
 * @returns
 */
export const confirmGood = (subOrderSn: string, memberId: string | number) => {
  return axios({
    method: 'post',
    url: '/order-api/order/confirm-receive',
    data: {
      subOrderSn,
      memberId
    }
  })
}

/**
 * 获取老商城历史订单数据
 * @param options
 * @returns
 */
export const getHistoryOrders = (options: any = {}) => {
  const { pageSize = 10, pageIndex = 1, status = -1 } = options
  return axios({
    method: 'post',
    url: '/order-api/old-mall/list',
    data: {
      pageSize,
      pageIndex,
      status
    }
  })
}

/**
 * 获取历史订单详情
 * @param orderId
 * @returns
 */
export const getHistoryOrdersDetail = (orderId: string | number) => {
  return axios({
    method: 'post',
    url: `/order-api/old-mall/detail?orderId=${orderId}`
  })
}

/**
 * 获取退款原因列表
 * @returns
 */
export const fetchGetRefundReasonList = () => {
  return axios({
    method: 'get',
    url: '/order-api/order/refund/type/list'
  })
}

/**
 * 批量上传图片
 * @param multipartFiles 图片数组
 * @returns
 */
export const fetchUploadImage = (multipartFiles: any) => {
  return axios({
    method: 'post',
    url: '/goods-api/goods/upload-batch',
    data: multipartFiles,
    config: {
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    }
  })
}

/**
 * 申请退款
 * @param param0 refundImageList: 图片列表, refundRemark： 具体原因描述,refundType：选择,subOrderSn： 订单
 * @returns
 */
export const fetchRefund = ({
  refundImageList,
  refundRemark,
  refundType,
  subOrderSn,
  skuIdList,
  refundReasonType
}: any) => {
  return axios({
    method: 'post',
    url: '/order-api/order/refund/save/new',
    data: {
      refundImageList,
      refundRemark,
      refundType,
      subOrderSn,
      skuIdList,
      refundReasonType
    }
  })
}

/**
 * 获取退款订单详情
 * @param refundSn
 * @returns
 */
export const fetchGetRefundDetail = (refundSn: string) => {
  return axios({
    method: 'get',
    url: `/order-api/order/refund/detail/new?refundSn=${refundSn}`
  })
}

/**
 * 撤销退款申请
 * @param refundSn 退款单号
 * @returns
 */
export const fetchRevokeRefund = (refundSn: string) => {
  return axios({
    method: 'post',
    url: `/order-api/order/refund/revoke?refundSn=${refundSn}`
  })
}
/*
 * 历史订单确认收货
 * @param orderId
 * @returns
 */
export const confirmHistoryConfirm = (orderId: string | number) => {
  return axios({
    method: 'post',
    url: `/order-api/old-mall/confirm?orderId=${orderId}`
  })
}

/**
 * 检测手机号是否可充值
 * @param mobile
 * @param skuId
 * @returns
 */
export const validateMobileIsRecharge = (mobile: string | number, skuId: number) => {
  return axios({
    method: 'POST',
    url: `/order-api/order/mobile-if-support-recharge?mobile=${mobile}&skuId=${skuId}`
  })
}

/**
 * 手机号归属地查询
 * @param mobile
 * @returns
 */
export const lookMobileRegion = (mobile: string | number) => {
  return axios({
    method: 'POST',
    url: `/order-api/order/mobile-region?mobile=${mobile}`
  })
}

/**
 * 获取用户可用优惠券列表
 * @returns
 */
export const getCouponsLists = () => {
  return axios({
    method: 'POST',
    url: '/goods-api/coupon/user-coupon-list'
  })
}

/**
 * 实时计算订单金额
 * @returns
 */
export const getRealTimePrice = (data: any) => {
  return axios({
    method: 'POST',
    url: '/order-api/order/real-time-cal-price',
    data
  })
}

/**
 * 获取物流列表
 */
export const getDeliverLists = (orderSn: string) => {
  return axios({
    method: 'POST',
    url: `/order-api/deliver/detail?orderSn=${orderSn}`
  })
}

/**
 * 海淘订单实名认证
 * @param realName 真实姓名
 * @param idCard 真实身份证号
 * @returns
 */
export const collectUserInfo = (realName: string, idCard: string) => {
  return axios({
    method: 'POST',
    url: '/order-api/order/real-name-authentication',
    data: {
      realName,
      idCard
    }
  })
}

/**
 * 发起售后前获取售后信息
 * @param orderSn
 * @param skuId
 * @returns
 */
export const refundPriceInfo = (orderSn: string, skuId: string | number) => {
  return axios({
    method: 'POST',
    url: '/order-api/order/refund/pre/refund-info',
    data: {
      orderSn,
      skuId
    }
  })
}

/**
 * 获取物流公司
 * @returns
 */
export const getLogisticsLists = () => {
  return axios({
    method: 'POST',
    url: '/order-api/order/company/list',
    data: {}
  })
}

/**
 * 获取售后列表
 * @param options
 * @returns
 */
export const getAfterLists = (options: any = {}) => {
  const { limit = 10, page = 1, req = {} } = options
  return axios({
    method: 'POST',
    url: '/order-api/order/refund/app/list/page',
    data: {
      limit,
      page,
      req
    }
  })
}

/**
 * 保存物流
 * @param data
 * @returns
 */
export const saveRefundLogis = (data: any) => {
  return axios({
    method: 'POST',
    url: '/order-api/order/refund/save/delivery',
    data
  })
}

/**
 * 获取取消原因列表
 * @returns
 */
export const fetchReasonList = (mainOrderSn: string) => {
  return axios({
    method: 'get',
    url: `/order-api/order/cancel/reason/list?mainOrderSn=${mainOrderSn}`
  })
}
