/*
 * @Author: your name
 * @Date: 2021-11-12 10:15:37
 * @LastEditTime: 2021-11-16 18:44:41
 * @LastEditors: your name
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/store/modules/user.ts
 */
import { getUserInfo } from '@/apis/user'

interface State {
  userInfo: object
}

const state: State = {
  userInfo: {}
}

const getters = {}

const mutations = {
  SET_USER_INFO(state: { userInfo: any }, payload: any) {
    state.userInfo = payload
  }
}

const actions = {
  async getUserInfo({ commit }: any, sessionId: string) {
    try {
      const { data }: any = await getUserInfo(sessionId)
      commit('SET_USER_INFO', data)
    } catch (error) {
      console.error('error ----> ', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
