/*
 * @Author:  zhangsong@yufu.cn
 * @Date: 2022-08-23 19:02:41
 * @LastEditors: tengwei
 * @LastEditTime: 2023-03-09 15:54:35
 * @FilePath: /yufu_h5_ssr/src/store/modules/search.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import { searchListV3, fetchScreenCodition } from '@/apis/search'
import { Toast } from 'vant'
import _ from 'lodash'

interface ReqType {
  brandIdList?: number[] | string[] // 品牌ID
  categoryIdList?: number[] | string[] // 分类ID
  collation?: number // 排序规则：1-正序，2-逆序
  keyWord?: string // 搜索关键词
  maxPrice?: number // 价格区间-最大价格
  minPrice?: number // 价格区间-最小价格
  sortType?: number // 排序类型：1-综合排序（毛利），2-销量排序，3-价格排序
  merId?: number // 商铺ID
}

interface SearchParamsType {
  limit: number
  orderBy: number
  page: number
  req: ReqType
}

interface State {
  resultList: object[]
  traceId?: string // 数据追踪ID
  brandList: object[] // 品牌列表
  categoryList: object[] // 分类列表
  resultCode: boolean
  // reqParams: SearchParamsType
  reqParams: SearchParamsType
  loading: boolean // 加载loading
  finished: boolean // 是否加载完成
  selectBrands: any // 筛选条件选中的品牌
  selectCategories: any // 筛选条件选中的分类
  totalNum: number | string
  isShowClass?: boolean // 是否展示分类
  minPrice?: string | number | null // 筛选最小值
  maxPrice?: string | number | null // 筛选最大值
}

const state: State = {
  resultList: [],
  brandList: [],
  categoryList: [],
  resultCode: false,
  loading: false,
  finished: false,
  reqParams: {
    limit: 24,
    orderBy: 1,
    page: 1,
    req: {}
  },
  selectBrands: [],
  selectCategories: [],
  totalNum: 0,
  isShowClass: true,
  minPrice: null,
  maxPrice: null,
  traceId: ''
}

const getters = {}

const mutations = {
  SET_RESULT_LIST(state: { resultList: object[] }, payload: object[]) {
    state.resultList = [...state.resultList, ...payload]
  },
  SET_RESULT_TRACEID(state: { traceId: string }, payload: string) {
    state.traceId = payload
  },
  SET_RESULT_LIST_NO(state: { resultList: object[] }) {
    state.resultList = []
  },
  SET_BRAND_LIST(state: { brandList: object[] }, payload: object[]) {
    state.brandList = payload
  },
  SET_CATEGORY_LIST(state: { categoryList: object[] }, payload: object[]) {
    state.categoryList = payload
  },
  SET_LIST_LOADING(state: { loading: boolean }, payload: boolean) {
    state.loading = payload
  },
  SET_LIST_FINISHED(state: { finished: boolean }, payload: boolean) {
    state.finished = payload
  },
  SELECT_BRANDS(state: { selectBrands: any; reqParams: any }, payload: any) {
    state.selectBrands = payload
    state.reqParams.req['brandIdList'] = payload
  },
  SELECT_CATETORIES(state: { selectCategories: any; reqParams: any }, payload: any) {
    state.selectCategories = payload
    state.reqParams.req['categoryIdList'] = payload
  },
  ONLY_RESET_SEARCH_CONDITION(state: {
    selectBrands: any
    selectCategories: any
    reqParams: any
    isShowClass: boolean
    minPrice: any
    maxPrice: any
  }) {
    if (!state.isShowClass) {
      state.reqParams.req['brandIdList'] = []
      state.selectBrands = []
      state.maxPrice = null
      state.minPrice = null
      return
    }
    state.reqParams.req['brandIdList'] = []
    state.selectBrands = []
    state.reqParams.req['categoryIdList'] = []
    state.selectCategories = []
    state.maxPrice = null
    state.minPrice = null
  },
  RESET_ALL_CONDITION(state: {
    selectBrands: any
    selectCategories: any
    reqParams: any
    resultList: any
    brandList: any
  }) {
    state.reqParams.req['brandIdList'] = []
    state.reqParams.req['categoryIdList'] = []
    state.selectCategories = []
    state.selectBrands = []
    state.resultList = []
    state.brandList = []
  },
  SET_SEARCH_PARAMS(state: any, payload: any) {
    state.reqParams = _.mergeWith(state.reqParams, payload)
  },
  SET_SALE_GOOD_NUM(state: any, payload: any) {
    state.totalNum = payload
  },
  SET_IS_SHOW_CLASS(state: { isShowClass: boolean }, payload: any) {
    state.isShowClass = payload
  },
  SET_GOOD_MAXPRICE(state: any, payload: any) {
    state.maxPrice = payload
  },
  SET_GOOD_MINPRICE(state: any, payload: any) {
    state.minPrice = payload
  },
  RESET_GOOD_PRICE(state: any) {
    state.minPrice = null
    state.maxPrice = null
  }
}

const actions = {
  setResultNo({ commit }: any) {
    commit('SET_RESULT_LIST_NO')
  },
  async getSearch({ commit }: any, data: any) {
    // 搜索
    try {
      if (data.req) {
        if (!_.isEqual(state.reqParams.req, data.req)) {
          state.resultList = []
          commit('SET_LIST_LOADING', false)
          commit('SET_LIST_FINISHED', false)
        }
      }
      state.reqParams = _.mergeWith(state.reqParams, data)
      commit('SET_LIST_LOADING', true)
      if (state.finished) return
      const response: any = await searchListV3(state.reqParams)
      if (response.code !== '000000') return Toast(response.msg)
      commit('SET_SALE_GOOD_NUM', response.data.totalNum)
      if (response.data.items.length > 0) {
        commit('SET_RESULT_LIST', response.data.items)
        commit('SET_RESULT_TRACEID', response.data.traceId)
        commit('SET_LIST_LOADING', false)
      } else {
        commit('SET_RESULT_LIST', response.data.items)
        commit('SET_LIST_LOADING', true)
        commit('SET_LIST_FINISHED', true)
      }
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async getCondtion({ commit }: any, data: any) {
    // 获取筛选条件
    try {
      const body = {
        keyWord: data.req.keyWord,
        merId: data.req.merId || '',
        brandIdList: data.req.brandIdList || [],
        categoryIdList: data.req.categoryIdList || []
        // collation: 0,
        // maxPrice: 0,
        // minPrice: 0,
        // sortType: 0
      }
      const response: any = await fetchScreenCodition(body)
      if (response.code !== '000000') return Toast(response.msg)
      commit('SET_BRAND_LIST', response.data.brandList)
      commit('SET_CATEGORY_LIST', response.data.categoryList)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  chooseBrand({ commit }: any, data: any) {
    // 选择品牌
    if (state.selectBrands.includes(data!.id)) {
      state.selectBrands = state.selectBrands.filter((val: any) => val !== data!.id)
    } else {
      state.selectBrands.push(data.id)
    }
    commit('SELECT_BRANDS', [...state.selectBrands])
  },
  chooseCategory({ commit }: any, data: any) {
    // 选择分类
    if (state.selectCategories.includes(data!.id)) {
      state.selectCategories = []
    } else if (state.selectCategories.length > 0 && !state.selectCategories.includes(data!.id)) {
      state.selectCategories = [data.id]
    } else {
      state.selectCategories.push(data.id)
    }
    commit('SELECT_CATETORIES', [...state.selectCategories])
  },
  // changePrice({ commit }: any, data: any) {
  //   // 修改价格
  //   console.log('修改价格', commit, data)
  // },
  resetCondition({ commit }: any) {
    commit('RESET_ALL_CONDITION')
  },
  onlyresetCondition({ commit }: any, isResetClass: boolean) {
    // 仅重置筛选参数
    commit('ONLY_RESET_SEARCH_CONDITION', isResetClass)
  },
  setSeachParams({ commit }: any, data: any) {
    commit('SET_SEARCH_PARAMS', data)
  },
  setIsShowClass({ commit }: any, data: any) {
    commit('SET_IS_SHOW_CLASS', data)
  },
  setGoodMaxPrice({ commit }: any, data: any) {
    commit('SET_GOOD_MAXPRICE', data)
  },
  setGoodMinPrice({ commit }: any, data: any) {
    commit('SET_GOOD_MINPRICE', data)
  },
  resetGoodPrice({ commit }: any) {
    commit('RESET_GOOD_PRICE')
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
