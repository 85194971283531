/*
 * @Author: tengwei
 * @Date: 2022-08-10 14:45:30
 * @LastEditors: Sll
 * @LastEditTime: 2023-06-16 17:47:40
 * @FilePath: /yufu_h5_fuka/src/utils/common.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import { Toast } from 'vant'
/**
 * 校验字段是否是连续的6位数字
 * @param target 需要校验的值
 */
export function isNotSeriesNum(target: string | number) {
  const tempStr = target + ''
  // eslint-disable-next-line no-constant-condition
  if (!/^\d{6}$/) return false // 不是6位数字
  if (/^(\d)\1+$/.test(tempStr)) return false // 6位相同数字

  let str = tempStr.replace(/\d/g, function ($0, pos) {
    return (parseInt($0) - pos) as unknown as string
  })
  if (/^(\d)\1+$/.test(str)) return false // 6位数顺序递增

  str = tempStr.replace(/\d/g, function ($0, pos) {
    return parseInt($0) + pos
  })
  if (/^(\d)\1+$/.test(str)) return false //  6位数顺序递减
  return true
}

// 获取schema参数
const getSchemaParam = (key: string, urlSearch: string) => {
  // 正则筛选地址栏
  const reg = new RegExp(`(^|&)${key}=([^&]*)(&|$)`)
  // 匹配目标参数
  const result = urlSearch.match(reg)
  // 返回参数值
  return result ? decodeURIComponent(result[2]) : null
}

/**
 * 获取schema的正确信息
 * @param url schema地址
 * @param paramsKey 需要的参数
 */
export const dealSchemaInfo = (url: string, paramsKey: string[]) => {
  console.log('url', url)
  const urlArr = url.split('?')
  const params: any = {}
  if (urlArr[1]) {
    paramsKey.map((item: string) => {
      params[item] = getSchemaParam(item, urlArr[1])
    })
  }
  const targetUrlArr = urlArr[0].split('/')
  const target = targetUrlArr[targetUrlArr.length - 1]
  return {
    target,
    params
  }
}

/**
 * 跳转正确的shema地址
 * @param url schema地址
 * @param paramsKey 需要的参数
 */
export const navigateRightSchemaPage = (url: string, paramsKey: string[]) => {
  const { target, params } = dealSchemaInfo(url, paramsKey)
  switch (target) {
    case 'detail':
      // 商品详情
      navigatorRightPage({
        url: `/pages/goods/goodsDetail/goodsDetail?id=${params.id}`
      })
      return
    default:
      window.location.href = url
      return
  }
}

export const navigatorRightPage = ({ url }: any) => {
  window.wx.miniProgram.navigateTo({
    url
  })
}

/**
 * 获取登录地址
 * @returns
 */

const isProd = import.meta.env.MODE === 'production' || import.meta.env.MODE === 'release'
export const getLoginUrl = () => {
  const url = encodeURIComponent(window.location.href)
  const xClient = encodeURIComponent(
    JSON.stringify({
      channelId: 3,
      platformId: sessionStorage.getItem('platformId') || 1003,
      source: sessionStorage.getItem('source') || '2'
    })
  )

  if (!isProd) {
    // 测试
    return `https://testapi.yufumall.cn/uc-api/h5/toH5LoginPage?callbackUrl=${url}&xclientInfo=${xClient}`
    //   return 'http://malltest.auth.yufu.cn/passport/oauth/authorize?client_id=yf_newMall_H5&redirect_uri=https://testwap.yufumall.cn/uc-api/h5/login-callback&response_type=code&scope=read'
  }
  // 线上
  return `https://api.yufumall.cn/uc-api/h5/toH5LoginPage?callbackUrl=${url}&xclientInfo=${xClient}`
  // return 'http://auth.yufu.cn/passport/oauth/authorize?client_id=yf_newMall_H5&redirect_uri=https://api.yufumall.cn/uc-api/h5/login-callback&response_type=code&scope=read'
}

/**
 * 每隔4个加空格
 * @param str
 * @returns
 */
export function replaceSpaceStr(str: string) {
  return str.replace(/(.{4})/g, '$1 ')
}

/**
 * 返回小程序页面
 * @param delta 返回多少层
 */
export function backMiniProgram(delta = 1) {
  window.wx.miniProgram.navigateBack({
    delta
  })
}

/**
 * 复制
 * @param val 要复制的内容
 */
export function copyText(val: string) {
  const input = document.createElement('input') // 直接构建input
  input.value = val // 设置内容
  document.body.appendChild(input) // 添加临时实例
  input.select() // 选择实例内容
  document.execCommand('Copy') // 执行复制
  document.body.removeChild(input) // 删除临时实例
  Toast('复制成功')
}

export const myRegExp = {
  // 检查字符串是否为合法QQ号码
  isQQ: function (str: string) {
    // 1 首位不能是0 ^[1-9]
    // 2 必须是 [5, 11] 位的数字 \d{4, 9}
    const reg = /^[1-9][0-9]{4,9}$/gim
    if (reg.test(str)) {
      return true
    } else {
      return false
    }
  },
  // 检查字符串是否为合法手机号码
  isPhone: function (str: string) {
    const reg =
      /^(0|86|17951)?(13[0-9]|15[012356789]|18[0-9]|14[0-9]|16[0-9]|17[0-9]|19[0-9])[0-9]{8}$/
    if (reg.test(str)) {
      return true
    } else {
      return false
    }
  }
}

/**
 * 比较两个版本号的大小
 * if left > right return -1
 * if left < right return 1
 * if left = right return 0
 */
export const compareVersion = (left: string, right: string) => {
  const regex = /^(\d+)\.*(\d*)\.*(\d*)/
  if (regex.test(left) && regex.test(right)) {
    const leftMatch = left.match(regex)!
    const rightMatch = right.match(regex)!
    const x = [parseInt(leftMatch[1]), parseInt(rightMatch[1])]
    const y = [parseInt(leftMatch[2]), parseInt(rightMatch[2])]
    const z = [parseInt(leftMatch[3]), parseInt(rightMatch[3])]
    if (x[1] !== x[0]) return x[1] > x[0] ? 1 : -1
    if (y[1] !== y[0]) return y[1] > y[0] ? 1 : -1
    if (z[1] !== z[0]) return z[1] > z[0] ? 1 : -1
    return 0
  }
}
