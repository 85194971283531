import {
  // createMemoryHistory,
  createRouter as _createRouter,
  createWebHistory,
  RouteRecordRaw
} from 'vue-router'

import { getUserInfo } from '@/apis/user'
import BROWSER from './utils/browser'
import yfBridge from './libs/yufu-bridge'
import { AplusQueusSendPV, mBuryPoint } from '@/utils/buryPoint'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'home',
    redirect: '/home',
    component: () => import('./components/NavBar/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: () => import('./pages/DigitalHome/index.vue'),
        // component: () => import('./pages/Home/index.vue'),
        meta: {
          index: 1 // 标识几级页面
        }
      },
      {
        path: '/classification', // 分类
        name: 'classification',
        component: () => import('./pages/Classification/index.vue'),
        meta: {
          index: 1, // 标识几级页面
          title: '分类'
        }
      },
      {
        path: '/shopcart', // 购物车
        name: 'shopCart',
        component: () => import('./pages/ShopCart/index.vue'),
        meta: {
          title: '购物车'
        }
      },
      {
        path: '/my', // 我的
        name: 'My',
        component: () => import('./pages/My/index.vue'),
        meta: {
          requiresAuth: false, // 自定义对象
          title: '我的'
        }
      }
    ]
  },
  {
    path: '/goods_logistics_lists', // 多物流列表信息
    name: 'goodsLogisticsList',
    component: () => import('./pages/GoodsLogisticsLists/index.vue'),
    meta: {
      title: '物流信息列表'
    }
  },
  {
    path: '/goods_logistics', // 物流信息
    name: 'goodsLogistics',
    component: () => import('./pages/GoodsLogistics/index.vue'),
    meta: {
      title: '物流信息'
    }
  },
  {
    path: '/shopcart_detail', // 二次跳转购物车购物车
    name: 'shopCartDetail',
    component: () => import('./pages/ShopCart/index.vue'),
    meta: {
      title: '购物车'
    }
  },
  {
    path: '/address', // 地址管理页面
    name: 'address',
    component: () => import('./pages/Address/index.vue'),
    meta: {
      index: 2,
      title: '地址列表'
    }
  },
  {
    path: '/address/edit', // 地址编辑页面
    name: 'addressEdit',
    component: () => import('@/pages/addrAddOrEdit/index.vue'),
    meta: {
      index: 3
    }
  },
  {
    path: '/good_detail', // 商品详情页
    name: 'gooddetail',
    component: () => import('./pages/GoodDetail/index.vue'),
    meta: {
      index: 2,
      title: '详情页'
    }
  },
  {
    path: '/coupons_list', // 商品详情页
    name: 'couponslist',
    component: () => import('./pages/CouponsList/index.vue'),
    meta: {
      index: 2,
      title: '我的优惠券'
    }
  },
  {
    path: '/login', // 登录页面
    name: 'login',
    component: () => import('./pages/Login/index.vue')
  },
  {
    path: '/login-forget', // 找回密码
    name: 'loginforget',
    component: () => import('./pages/ForgetPwd/index.vue')
  },
  {
    path: '/change_pwd', // 修改登录密码
    name: 'changepwd',
    component: () => import('./pages/ChangePwd/index.vue')
  },
  {
    path: '/checkstand', // 收银台
    name: 'checkstand',
    component: () => import('./pages/Checkstand/index.vue'),
    meta: {
      title: '收银台'
    }
  },
  {
    path: '/order', // 我的订单
    name: 'orders',
    component: () => import('./pages/Orders/index.vue'),
    meta: {
      title: '我的订单'
    }
  },
  {
    path: '/refund_order', // 退款/售后
    name: 'refundOrder',
    component: () => import('./pages/RefundAfterSale/index.vue'),
    meta: {
      title: '退款/售后'
    }
  },
  {
    path: '/order_detail', // 订单详情页
    name: 'orderdetail',
    component: () => import('./pages/OrderDetail/index.vue'),
    meta: {
      title: '订单详情页'
    }
  },
  {
    path: '/order_history', // 历史订单
    name: 'ordershistory',
    component: () => import('./pages/OrdersHistory/index.vue'),
    meta: {
      title: '历史订单'
    }
  },
  {
    path: '/order_confirmtion', // 订单确认页
    name: 'orderconfirm',
    component: () => import('./pages/OrderConfirm/index.vue'),
    meta: {
      title: '确认订单'
    }
  },
  {
    path: '/setting', // 设置
    name: 'setting',
    component: () => import('./pages/Setting/index.vue'),
    meta: {
      title: '设置'
    }
  },
  {
    path: '/search', // 搜索页
    name: 'search',
    component: () => import('./pages/Search/index.vue'),
    meta: {
      title: '搜索页'
    }
  },
  {
    path: '/search-shop', // 店铺搜索页
    name: 'searchshop',
    component: () => import('./pages/SearchShop/index.vue'),
    meta: {
      title: '店铺搜索页'
    }
  },
  {
    path: '/my-points', // 我的积分
    name: 'mypoints',
    component: () => import('./pages/MyPoints/index.vue'),
    meta: {
      title: '我的积分'
    }
  },
  {
    path: '/pay_result', // 支付结果页
    name: 'payresult',
    component: () => import('./pages/PayResult/index.vue'),
    meta: {
      title: '支付结果页'
    }
  },
  {
    path: '/yf_mobile_recharge', // 手机充值中心
    name: 'mobileRecharge',
    component: () => import('./pages/MobileRecharge/index.vue'),
    meta: {
      title: '充值中心'
    }
  },
  {
    path: '/yf_mobile_record', // 手机充值记录
    name: 'mobileRecords',
    component: () => import('./pages/MobileRecords/index.vue'),
    meta: {
      title: '充值记录'
    }
  },
  {
    path: '/yf_mobile_record_detail', // 手机充值详情
    name: 'mobileRecordDetail',
    component: () => import('./pages/MobileRecordDetail/index.vue'),
    meta: {
      title: '充值详情'
    }
  },
  {
    path: '/set_pay_pwd', // 设置或者修改支付密码
    name: 'setpaypwd',
    component: () => import('./pages/SetOrChangPayPwd/index.vue')
  },
  {
    path: '/integral_list', // 积分明细列表
    name: 'IntegralList',
    component: () => import('./pages/IntegralList/index.vue'),
    meta: {
      title: '积分明细列表'
    }
  },
  {
    path: '/integral_detail', // 积分明细详情
    name: 'IntegralDetail',
    component: () => import('./pages/IntegralDetail/index.vue'),
    meta: {
      title: '积分明细详情'
    }
  },
  {
    path: '/shop_list', // 商铺列表页
    name: 'shoplist',
    component: () => import('./pages/shopList/index.vue'),
    meta: {
      title: '商铺列表页'
    }
  },
  {
    path: '/shop_detail', // 商铺详情页
    name: 'shopdetail',
    component: () => import('./pages/shopDetail/index.vue'),
    meta: {
      title: '店铺详情'
    }
  },
  {
    path: '/rever_list', // 我的预约页面
    name: 'reverlist',
    component: () => import('./pages/ReverList/index.vue')
  },
  {
    path: '/account_safe', // 账户与安全
    name: 'accountsafe',
    component: () => import('./pages/AccountSafe/index.vue')
  },
  {
    path: '/refund_type', // 选择退款类型
    name: 'refundType',
    component: () => import('./pages/RefundType/index.vue')
  },
  {
    path: '/refund_info', // 填写退货信息
    name: 'refundInfo',
    component: () => import('./pages/RefundInfo/index.vue')
  },
  {
    path: '/refund', // 退款
    name: 'refund',
    component: () => import('./pages/Refund/index.vue')
  },
  {
    path: '/refund_detail', // 退款详情
    name: 'refunddetail',
    component: () => import('./pages/RefundDetail/index.vue'),
    meta: {
      title: '退款详情'
    }
  },
  {
    path: '/digital_card', // 数字福卡列表
    name: 'digitalcard',
    component: () => import('./pages/DigitalCard/index.vue'),
    meta: {
      title: '数字福卡列表'
    }
  },
  {
    path: '/digital_card_detail', // 数字福卡详情
    name: 'digitalCardDetail',
    component: () => import('./pages/DigitalCardDetail/index.vue'),
    meta: {
      title: '数字福卡详情'
    }
  },
  {
    path: '/add_digital_card', // 添加数字福卡
    name: 'AddDigitalCard',
    component: () => import('./pages/DigitalCardAdd/index.vue'),
    meta: {
      title: '添加数字福卡'
    }
  },
  {
    path: '/yufu_jd_home', // 京东优选
    name: 'jdHome',
    component: () => import('./pages/JdHome/index.vue'),
    meta: {
      title: '京东优选'
    }
  },
  {
    path: '/discount_coupon', //专属权益
    name: 'DiscountCoupon',
    component: () => import('./pages/DiscountCoupon/index.vue'),
    meta: {
      title: '专属权益'
    }
  },
  {
    path: '/activity_rules', //活动规则
    name: 'ActivityRules',
    component: () => import('./pages/ActivityRules/index.vue'),
    meta: {
      title: '活动细则'
    }
  },
  {
    path: '/card_details', // 查看详情
    name: 'CardDetails',
    component: () => import('./pages/CardDetails/index.vue'),
    meta: {
      title: '查看详情'
    }
  },
  {
    path: '/credit_card',
    name: 'CreditCard',
    component: () => import('./pages/CreditCard/index.vue')
  },
  {
    path: '/recharge_center', // 充值中心
    name: 'rechargeCenter',
    component: () => import('./pages/RechargeCenter/index.vue'),
    meta: {
      title: '充值中心'
    }
  },
  {
    path: '/auth_space', // 数字福卡空白授权页面
    name: 'authspace',
    component: () => import('./pages/AuthSpace/index.vue'),
    meta: {
      title: ''
    }
  },
  {
    path: '/fuka', // 福卡首页H5
    name: 'fukaHome',
    component: () => import('./pages/FukaHome/index.vue')
  },
  {
    path: '/:pathMatch(.*)*',
    name: '404',
    component: () => import('./pages/404/index.vue'),
    meta: { title: '页面丢失了' }
  }
]

export function createRouter() {
  const router = _createRouter({
    // use appropriate history implementation for server/client
    // import.meta.env.SSR is injected by Vite.
    // history: import.meta.env.SSR ? createMemoryHistory() : createWebHistory(),
    history: createWebHistory(),
    routes
  })
  router.beforeEach(async (to, from, next) => {
    if (
      (to.query.platformId && !localStorage.getItem('platformId')) ||
      (to.query.platformId && sessionStorage.getItem('platformId') !== to.query.platformId)
    ) {
      sessionStorage.setItem('platformId', to.query.platformId as string)
    }
    if (
      (to.query.channelId && !localStorage.getItem('channelId')) ||
      (to.query.channelId && sessionStorage.getItem('channelId') !== to.query.channelId)
    ) {
      sessionStorage.setItem('channelId', to.query.channelId as string)
    }
    if (
      (to.query.source && !localStorage.getItem('channelSource')) ||
      (to.query.source && localStorage.getItem('channelSource') !== to.query.source)
    ) {
      localStorage.setItem('channelSource', to.query.source as string)
      sessionStorage.setItem('source', to.query.source as string)
    }
    if (to.query.source === '2' && to.name === 'home') {
      // source 2 -- 福卡渠道
      document.title = '福卡商城'
      localStorage.setItem('channelSource', to.query.source)
      sessionStorage.setItem('source', to.query.source as string)
    } else {
      document.title = '数字福卡'
    }

    if (yfBridge.hasNativeMethod('getAppXClient')) {
      yfBridge.call('getAppXClient', (data: string) => {
        console.log('xClient 参数', JSON.parse(data))
        const res: any = JSON.parse(data)
        if (res && Number(res.source) === 1) {
          // 判断是否是数字福卡App环境
          sessionStorage.setItem('isApp', '1')
        }
      })
    }
    if (to.query.from === 'wechat_applet') {
      sessionStorage.setItem('isMiniProgram', '1')
    }

    // 包含sessionId,并且from=wechat_applet，就将本地sessionId和传入的做对比，判断是否获取用户信息
    // to.query.sessionId != JSON.parse(window.localStorage.getItem('sessionId') as string)
    if (to.query.sessionId) {
      if (to.query.sessionId !== (sessionStorage.getItem('historySessionId') as string)) {
        localStorage.setItem('sessionId', to.query.sessionId as string)
        // 记录历史sessionId
        sessionStorage.setItem('historySessionId', to.query.sessionId as string)
      } else if (to.query.sessionId !== (localStorage.getItem('sessionId') as string)) {
        // 更新url的sessionId为最新值
        router.replace({
          // name: 'mobileRecharge',
          path: from.path,
          query: { sessionId: localStorage.getItem('sessionId') as string }
        })
      }

      window.localStorage.setItem('sessionId', to.query.sessionId as string)
      const { data }: any = await getUserInfo(to.query.sessionId as string)
      window.localStorage.setItem('userInfo', JSON.stringify(data))
    }
    // 新版的用户标识改为了token
    if (to.query.token && to.query.token != (window.localStorage.getItem('sessionId') as string)) {
      window.localStorage.setItem('sessionId', to.query.token as string)
      const { data }: any = await getUserInfo(to.query.token as string)
      window.localStorage.setItem('userInfo', JSON.stringify(data))
    }
    // 这个目的是为了小程序先进入了多页面项目的页面，没有存储userinfo的信息做的处理
    if (
      to.query.from === 'wechat_applet' &&
      window.localStorage.getItem('sessionId') &&
      !window.localStorage.getItem('userInfo')
    ) {
      const { data }: any = await getUserInfo(window.localStorage.getItem('sessionId') as string)
      window.localStorage.setItem('userInfo', JSON.stringify(data))
    }

    next()
  })

  router.afterEach((to, from) => {
    AplusQueusSendPV({
      from: from.meta.title || ''
    })
    mBuryPoint('H5_OPEN_APP_PAGE', {
      from: from.meta.title || ''
    })
    if (to.meta.title) {
      document.title = to.meta.title as string
      if (BROWSER?.isiOS) {
        yfBridge.call('browserTitle', { title: to.meta.title })
      }
    }
  })

  return router
}

export const router = createRouter()
