/*
 * @Author: your name
 * @Date: 2021-11-11 10:49:33
 * @LastEditTime: 2023-03-02 16:32:30
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/store/modules/detail.ts
 */

import { getSpuDetail, getSkuSpec, getMerchantInfoBySpu } from '@/apis/detail'
import { addCart } from '@/apis/cart'
interface State {
  defaultData: object
  addCartSuccess?: boolean
  goodsSku: object
  merchant?: object | null
  salePrice?: string | null
  chooseGoodNum?: string | number
  // specGroupList: any[]
}

const state: State = {
  defaultData: {},
  addCartSuccess: false,
  goodsSku: {},
  merchant: null,
  salePrice: null,
  chooseGoodNum: 1
}

const getters = {
  defaultData(state: { defaultData: any }) {
    return Object.assign({}, state.defaultData)
  },
  specGroupList(state: { defaultData: { specGroupList: any } }) {
    return state.defaultData && state.defaultData.specGroupList
  },
  chooseSkuDetail(state: { goodsSku: any }) {
    let selectedTxt = ''
    if (state.goodsSku && JSON.stringify(state.goodsSku) !== '{}') {
      state.goodsSku.specList.forEach((val: any) => {
        selectedTxt += `${val.value} `
      })
    }
    return selectedTxt
  }
}

const mutations = {
  SET_DEFAULT_DETAIL_DATA(state: { defaultData: any }, payload: any) {
    state.defaultData = payload
  },
  SET_SPECGROUP_LIST(state: { defaultData: { specGroupList: any } }, payload: any) {
    state.defaultData.specGroupList = payload
  },
  SET_GOODS_SKU(state: { goodsSku: any }, payload: any) {
    state.goodsSku = payload
  },
  SET_MERCHANT_INFO(state: any, payload: any) {
    state.merchant = payload
  },
  RESET_GOODS_SKUS(state: any) {
    state.goodsSku = {}
  },
  SET_CHOOSE_GOOD_NUM(state: { chooseGoodNum: string | number }, payload: number) {
    state.chooseGoodNum = payload
  }
}

const actions = {
  async getMerchant({ commit }: any, spuId: string) {
    try {
      const { data, code }: any = await getMerchantInfoBySpu(spuId)
      if (code === '000000') {
        commit('SET_MERCHANT_INFO', data)
      }
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async getSpuDetail({ commit }: any, spuId: string) {
    try {
      const { data }: any = await getSpuDetail(spuId)
      commit('SET_DEFAULT_DETAIL_DATA', data)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async addCart({ commit }: any, req: any) {
    try {
      const { data, code, msg }: any = await addCart(req)
      console.log('data', data, code, msg)
      console.log('commit--used', commit)
    } catch (error) {
      console.error('error ---> ', error)
    }
  },
  async getSkuSpec({ commit }: any, req: any) {
    try {
      const { data }: any = await getSkuSpec(req)
      commit('SET_GOODS_SKU', data.goodsSku)
      commit('SET_SPECGROUP_LIST', data.specGroupList)
    } catch (error) {
      console.error('error -----> ', error)
    }
  },
  async resetGoodSpu({ commit }: any) {
    commit('RESET_GOODS_SKUS')
  },
  async setGoodNum({ commit }: any, data: number) {
    commit('SET_CHOOSE_GOOD_NUM', data)
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
