/*
 * @Author: your name
 * @Date: 2021-10-25 09:30:08
 * @LastEditTime: 2023-02-15 16:01:26
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/main.ts
 */
import App from './App.vue'
import { createApp as _createApp } from 'vue'
// import { createRouter } from './router'
import { router } from './router'
import { createStore } from './store/index'
import Vant from 'vant'
import 'vant/lib/index.css'
import './styles/index.less'
// 引入js-sdk
function getJsSdk() {
  const script = document.createElement('script')
  script.type = 'text/javascript'
  script.src = './js-sdk-1.6.0.js'
  document.getElementsByTagName('head')[0].appendChild(script)

  script.onload = () => {
    // 需要初始化sdk环境，等等...
  }
}
getJsSdk()
// if (!import.meta.env.SSR) {
//   const script = document.createElement('script')
//   script.type = 'text/javascript'
//   script.src = './js-sdk-1.6.0.js'
//   document.getElementsByTagName('head')[0].appendChild(script)

//   script.onload = () => {
//     // 需要初始化sdk环境，等等...
//   }
// }
// SSR requires a fresh app instance per request, therefore we export a function
// that creates a fresh app instance. If using Vuex, we'd also be creating a
// fresh store here.
export function createApp() {
  const app = _createApp(App)
  // const router = createRouter()
  const store = createStore()
  app.use(router).use(store).use(Vant)
  return { app, router, store }
}
