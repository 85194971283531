<!--
 * @Author: your name
 * @Date: 2021-10-27 09:24:29
 * @LastEditTime: 2022-12-06 18:31:34
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/App.vue
-->
<template>
  <router-view v-slot="{ Component }" :key="route.fullPath" class="router-view">
    <Suspense>
      <template #default>
        <component :is="Component" />
      </template>
      <template #fallback>
        <!-- <div class="error_component">页面加载出错</div> -->
      </template>
    </Suspense>
  </router-view>
</template>
<script lang="ts">
  import { useRoute } from 'vue-router'

  export default {
    setup() {
      return {
        route: useRoute()
      }
    }
  }
</script>
<style lang="less"></style>
