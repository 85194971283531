/*
 * @Author: your name
 * @Date: 2021-11-15 18:50:16
 * @LastEditTime: 2023-04-07 13:54:41
 * @LastEditors: tengwei
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /yufu_h5_ssr/src/apis/user.ts
 */
// 用户相关接口

import axios from '@/utils/request'

/**
 * 获取用户信息
 * @param sessionId
 * @returns
 */

export const getUserInfo = (sessionId?: string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/get-member-by-session-id',
    config: {
      sessionId
    }
  })
}

/**
 * 获取默认地址
 */
export const fetchGetDefaultAddress = () => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/receive/address/default'
  })
}

/**
 * 获取重置支付密码验证码（身份证号验证通过给用户发送短信验证码）
 * "captcha": "验证码",
 * "idCard": "身份身份证号",
 * "mobile": "手机号"
 * @returns
 */
export const resetPayPwd = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/get-reset-pay-password-captcha',
    data
  })
}

/**
 * 重置支付密码
 * @param data
 * @returns
 */
export const savePayPwd = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/reset-pay-password',
    data
  })
}

/**
 * 校验忘记支付密码的短信验证码是否正确
 * @param data
 * @returns
 */
export const validPayCaptcha = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/validate-pay-password-captcha',
    data
  })
}

/**
 * 校验原支付密码是否正确
 * @param payPassword
 * @returns
 */
export const validPayOldPwd = (payPassword: string) => {
  return axios({
    method: 'post',
    url: `/uc-api/uc/member/validate-pay-password?payPassword=${payPassword}`
  })
}

/**
 * 检查用户是否设置过支付密码
 * @returns
 */
export const validifPayPwd = () => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/if-absent-pay-password'
  })
}

/**
 * 获取重置密码验证码
 * @param mobile 手机号
 * @returns
 */
export const getResetCaptcha = (mobile: string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/getResetPasswordCaptcha',
    data: {
      mobile
    }
  })
}

/**
 * 找回密码时验证验证码
 * @param mobile
 * @param captcha
 * @returns
 */
export const validResetCaptcha = (mobile: string, captcha: string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/captcha/forget-password',
    data: {
      mobile,
      captcha
    }
  })
}

/**
 * 重置密码
 * @param mobile
 * @param password
 * @returns
 */
export const saveResetPwd = (mobile: string, password: string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/reset-password',
    data: {
      mobile,
      password
    }
  })
}

/**
 * 获取用户实名认证信息
 * @returns
 */
export const getRealName = () => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/real-name/get'
  })
}

/**
 * 初始化微信接口
 * @param url 回调地址
 * @returns
 */
export const getWechatInit = (url: string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/third-party/web-wx-share-token-params',
    data: {
      url
    }
  })
}

/**
 * 获取卡券数量
 * @returns
 */
export const getMcCount = () => {
  return axios({
    method: 'get',
    url: '/order-api/card-voucher/not-invalid-count'
  })
}

/**
 * 个人中心接口
 * @returns
 */
export const getUcList = () => {
  return axios({
    method: 'get',
    url: '/goods-api/goods/uc/list'
  })
}

/**
 * 美团免登接口
 * @param productType productType业务类型分别为dp_canyin（餐饮）、mt_waimai(外卖)、mt_hotel(酒店)和mt_maicai(美团买菜)
 * @returns
 */
export const oAuthMeituan = (productType: string) => {
  return axios({
    method: 'POST',
    url: `/uc-api/mt/commonaccess?productType=${productType}`
  })
}
