/***
 * 创建 axios 实例
 * 详细请求方式设置到 request.ts
 */

import axios, { AxiosResponse } from 'axios'
// import { useRouter, Router } from 'vue-router'
import { Toast, Dialog } from 'vant'
import { isAuth } from '@/helpers/isAuth'
import { getLoginUrl } from './common'
import { getQueryString } from '@/utils/url'
import { getRandomCode } from './buryPoint'

//创建axios的一个实例
const instance = axios.create({
  // baseURL: isProd ? '/' : 'http://localhost:3000/', //接口统一域名
  baseURL: '/', //接口统一域名
  // timeout: 10000, //设置超时
  headers: {
    'Content-Type': 'application/json;charset=UTF-8;'
  }
})

let loading: any
//正在请求的数量, 用来理重复请求的问题
let requestCount = 0
let message = ''
//显示loading
const showLoading = () => {
  if (requestCount === 0 && !loading) {
    loading = Toast.loading({
      message: '加载中...',
      forbidClick: true
    })
  }
  requestCount++
}

//隐藏loading
const hideLoading = () => {
  requestCount--
  if (requestCount == 0) {
    loading.close()
  }
}

// 刷新sessionId
function refreshSessionId() {
  return axios.create()({
    method: 'POST',
    url: `/uc-api/uc/member/refesh-session?sessionId=${isAuth()}`,
    headers: {
      'X-Client': JSON.stringify({
        channelId: getQueryString('channelId') || sessionStorage.getItem('channelId'),
        deviceId: getRandomCode(),
        fixVersion: 1,
        mainVersion: 1,
        os: 'H5',
        platformId: getQueryString('platformId') || sessionStorage.getItem('platformId'),
        pm: 'H5',
        source: getQueryString('source') || localStorage.getItem('channelSource') || '2',
        screenSize: 'x',
        signVersion: 1,
        subVersion: 1,
        systemVersion: 'x',
        sessionId: (isAuth() && isAuth()) || ''
      })
    }
  })
}

// 重定向到登录页
function redirectLogin(msg: string, response: AxiosResponse) {
  localStorage.removeItem('sessionId')
  localStorage.removeItem('userInfo')
  Dialog.confirm({
    message: `${msg},请重新登录`
  })
    .then(() => {
      location.assign(getLoginUrl())
    })
    .catch(() => {})
  return response.data
}

//请求拦截器
instance.interceptors.request.use(
  async (config: any) => {
    showLoading()
    //若请求方式为post，则将data参数转为JSON字符串
    // config.headers['sessionId'] = 'b2485fb767a09c306abef54a7ddf51b93e286fef'
    config.headers['sessionId'] = (isAuth() && isAuth()) || ''
    config.headers['X-Client'] = JSON.stringify({
      channelId: getQueryString('channelId') || sessionStorage.getItem('channelId') || 3,
      deviceId: 'x',
      fixVersion: 1,
      mainVersion: 1,
      os: 'H5',
      platformId: getQueryString('platformId') || sessionStorage.getItem('platformId') || 1003,
      pm: 'H5',
      source: getQueryString('source') || localStorage.getItem('channelSource') || '1',
      screenSize: 'x',
      signVersion: 1,
      subVersion: 1,
      systemVersion: 'x',
      sessionId: (isAuth() && isAuth()) || ''
    })
    config.headers['callbackUrl'] = location.href
    return config
  },
  (error) => Promise.reject(error)
)

//响应拦截器
let isRfreshing = false // 控制刷新 session 的状态
let requests: any[] = [] // 存储刷新 session 期间过来的 请求
instance.interceptors.response.use(
  (response: AxiosResponse<unknown, any>) => {
    if (typeof window !== 'undefined') {
      hideLoading()
    }
    //请求成功
    const { code, msg }: any = response.data
    console.log(code, 'code')
    // 用户未登录 或 sessionId 过期
    if (code.match(/^99/)) {
      if (sessionStorage.getItem('isWap')) {
        redirectLogin(msg, response)
      } else {
        // 62aa0dca7c5aaf3fc9df7cccd043ff04372c38b8
        // 刷新 sessionId
        if (!isRfreshing) {
          isRfreshing = true // 开启刷新状态
          // 尝试刷新获取新的 Session
          return refreshSessionId()
            .then((res) => {
              const { data }: any = res.data
              if (!data) {
                throw new Error('刷新 Session 失败')
              }
              // 刷新 Session 成功了
              localStorage.setItem('sessionId', data)
              // 把 requests 队列中的请求重新发出去
              requests.forEach((cb) => cb())
              // 重置 requests 数组
              requests = []
              return instance(response.config)
            })
            .catch((err) => {
              console.log(err)
              // redirectLogin(msg, response)
              return Promise.reject(response)
            })
            .finally(() => {
              isRfreshing = false // 重置刷新状态
            })
        }
        // 刷新状态下，把请求挂起放到 requests 数组中
        return new Promise((resolve) => {
          requests.push(() => {
            resolve(instance(response.config))
          })
        })
      }
    }
    if (code !== '000000') {
      if (msg && msg !== '') {
        Toast(msg)
      }
      return response.data
    }
    return response.data
  },
  (error) => {
    //请求失败
    if (error.response && error.response.status) {
      const status = error.response.status
      switch (status) {
        case 400:
          message = '请求错误'
          break
        case 401:
          message = '请求错误'
          break
        case 404:
          message = '请求地址出错'
          break
        case 408:
          message = '请求超时'
          break
        case 500:
          message = '服务器内部错误!'
          break
        case 501:
          message = '服务未实现!'
          break
        case 502:
          message = '网关错误!'
          break
        case 503:
          message = '服务不可用!'
          break
        case 504:
          message = '网关超时!'
          break
        case 505:
          message = 'HTTP版本不受支持'
          break
        default:
          message = '请求失败'
      }
      Toast.fail(message)
      return Promise.reject(error)
    }
    return Promise.reject(error)
  }
)

export default instance
