import {
  calculateFee,
  findAddressById,
  getAddressList,
  getRegion,
  saveAddress
} from '@/apis/address'

interface State {
  areaLists: any[]
  saveAddressSuccess: boolean
  feeDetail: object
  editAddres: object
  addressInfo: any
  singleAddressInfo: object // 添加地址之后的单地址详情
}

const state: State = {
  areaLists: [],
  saveAddressSuccess: false,
  addressInfo: {
    addressList: [],
    success: false
  },
  feeDetail: {},
  editAddres: {},
  singleAddressInfo: {}
}

const getters = {}

const mutations = {
  SET_AREA_LIST(state: any, payload: any) {
    state.areaLists = payload
  },
  SAVE_ADDRESS_SUCCESS(state: any, payload: any) {
    // state.saveAddressSuccess = payload
    state.singleAddressInfo = payload.data
  },
  SET_ADDRESS_LIST(state: { addressInfo: any }, payload: any) {
    state.addressInfo = {
      addressList: payload.data || [],
      success: payload.code === '000000' ? true : false
    }
  },
  SET_FEE_DETAIL(state: { feeDetail: any }, payload: any) {
    state.feeDetail = payload
  },
  SET_EDIT_ADDRESS(state: { editAddres: any }, payload: any) {
    state.editAddres = payload
  }
}

const actions = {
  async getRegion({ commit }: any, parentCode: string) {
    try {
      const { data }: any = await getRegion(parentCode)
      commit('SET_AREA_LIST', data)
    } catch (error) {
      console.error('error -----> ', error)
    }
  },
  async getAddressList({ commit }: any) {
    try {
      const res: any = await getAddressList()
      commit('SET_ADDRESS_LIST', res)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async saveAddress({ commit }: any, body: any) {
    try {
      const res: any = await saveAddress(body)
      commit('SAVE_ADDRESS_SUCCESS', res)
      // commit('SAVE_ADDRESS_SUCCESS', true)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async calculateFee({ commit }: any, body: any) {
    try {
      const { data }: any = await calculateFee(body)
      commit('SET_FEE_DETAIL', data)
    } catch (error) {
      console.error('error ----> ', error)
    }
  },
  async findAddressById({ commit }: any, body: string) {
    try {
      const { data }: any = await findAddressById(body)
      commit('SET_EDIT_ADDRESS', data)
    } catch (error) {
      console.error('error ----> ', error)
    }
  }
}

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
