// 用户相关接口

import axios from '@/utils/request'

// 验证码登录
export const loginCap = (data: object) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/captcha/login',
    data
  })
}

// 获取验证码
export const getCaptcha = (mobile: number | string) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/get-login-captcha',
    data: {
      mobile
    }
  })
}

/**
 *
 * @param mobile 手机号
 * @param captcha 验证码
 */
export const validCaptcha = (data: object) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/captcha/forget-password',
    data
  })
}

/**
 * 重置密码
 * @param data
 * @returns
 */
export const resetPwd = (data: object) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/reset-password',
    data
  })
}

/**
 *
 * @param data username 用户名
 * @param data password 密码
 * @returns
 */
export const loginPwd = (data: object) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/password/login',
    data
  })
}

interface RealnameAuthType {
  idCard: string
  name: string
}
/**
 * 实名
 * @param data
 * @returns
 */
export const fetchRealnameAuth = (data: RealnameAuthType) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/real-name/save',
    data
  })
}

/**
 * 退出登录
 * @param data
 * @returns
 */
export const logOut = (data: any) => {
  return axios({
    method: 'post',
    url: '/uc-api/uc/member/sign-out',
    data
  })
}

/**
 * 切换环境
 */
export const fetchSwitchProdDev = () => {
  return axios({
    method: 'GET',
    url: '/uc-api/switch/mini-program'
  })
}
