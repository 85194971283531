/*
 * @Author: tengwei
 * @Date: 2022-09-21 10:56:58
 * @LastEditors: tengwei
 * @LastEditTime: 2022-12-16 18:54:04
 * @FilePath: /yufu_h5_ssr/src/helpers/isAuth.ts
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

// const isAuth = async () => {
export function isAuth(): any {
  if (typeof window !== 'undefined') {
    const sessionId = localStorage.getItem('sessionId')
    if (sessionId) {
      return sessionId
    }
    return false
  }
}

// export { isAuth }
